<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('board_members')" :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('board_members')" :isFilter="false"/>
            </template>
            <ValidationObserver ref="form">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                        <ValidationProvider name="faculty_code" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox
                                    @change="getMembers"
                                    v-model="form.faculty_code">
                                </faculty-selectbox>
                                <b-form-invalid-feedback v-if="errors[0]"
                                                         v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                        <ValidationProvider name="user_id" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('name_surname')" class="position-relative">
                                <vue-autosuggest
                                    class=" w-100"
                                    ref="autosuggest"
                                    v-model="query"
                                    :suggestions="suggestions"
                                    :inputProps="inputProps"
                                    :sectionConfigs="sectionConfigs"
                                    :getSuggestionValue="getSuggestionValue"
                                    @input="fetchResults">
                                    <template v-slot="{ suggestion }">
                                        <div>{{ suggestion.item.name + ' ' + suggestion.item.surname }}</div>
                                    </template>
                                </vue-autosuggest>
                                <input type="hidden" v-model="form.user_id"/>
                                <b-form-invalid-feedback v-if="errors[0]"
                                                         v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                        <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('member_type')">
                                <parameter-selectbox
                                    :validate-error="errors[0]"
                                    code="board_member_types"
                                    v-model="form.type"
                                ></parameter-selectbox>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                        <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('status')">
                                <status-selectbox
                                    :validate-error="errors[0]"
                                    v-model="form.status"
                                ></status-selectbox>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <b-form-group>
                            <b-button variant="primary mr-3" @click="addMember"><span class="d-flex"><i
                                class="ri-add-line mr-2"></i>{{ $t('add') }}</span></b-button>
                        </b-form-group>
                    </div>

                </div>
            </ValidationObserver>
            <b-table v-if="items.length>0"
                     empty-text="Tabloda herhangi bir veri mevcut değil."
                     bordered
                     striped
                     responsive
                     :items="items"
                     :fields="fields"
                     show-empty
                     class="mb-5 table-dropdown no-scrollbar border rounded">
                <template #cell(actions)="row">
                    <b-button class="text-danger" variant="link" size="xs" @click="deleteMember(row.item)">{{ $t('delete').toUpper() }}</b-button>
                </template>
            </b-table>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// component
import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox';
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';

// Service
import BoardMemberService from '@/services/BoardMemberService';
import StaffService from '@/services/StaffService';

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import qs from 'qs';

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        StatusSelectbox,
        FacultySelectbox,
        ParameterSelectbox,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            form: {},
            searchText: '',
            debounceMilliseconds: 200,
            inputProps: {
                id: 'autosuggest__input',
                placeholder: this.$t('search') + '...',
                class: 'form-control'
            },
            query: '',
            selected: '',
            data: [],
            sectionConfigs: {
                limit: 10,
                default: {
                    onSelected: (item) => {
                        this.form.user_id = item.item.user_id;
                    }
                }
            },
            items: [],
            fields: [
                {key: 'person.name', label: this.$options.filters.toUpperCase(this.$t('name')), sortable: true},
                {key: 'person.surname', label: this.$options.filters.toUpperCase(this.$t('surname')), sortable: true},
                {
                    key: ('type_name'),
                    label: this.$options.filters.toUpperCase(this.$t('member_type')),
                    sortable: true
                },
                {key: 'status', label: this.$t('status'), sortable: true, formatter: this.getStatusText},
                {key: 'actions', label: '', sortable: false, class: 'w-40'}
            ]
        }
    },
    metaInfo() {
        return {
            title: this.$t('board_members')
        }
    },
    methods: {
        getMembers() {
            if (!this.form.faculty_code) {
                return;
            }
            const config = {
                params: {
                    filter: {faculty_code: this.form.faculty_code},
                    limit: 999,
                    sort: 'id'
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            BoardMemberService.getAll(config)
                              .then(response => {
                                  this.items = response.data.data;
                              })
                              .catch(e => {
                                  if (e.status !== undefined && e.data.message) {
                                      this.$toast.error(this.$t('api.' + e.data.message));
                                  }
                              });
        },
        async addMember() {
            if (this.checkPermission('boardmember_store')) {
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    BoardMemberService.store(this.form).then(response => {
                        this.getMembers();
                        this.form.user_id = null;
                        this.form.type = null;
                        this.form.status = null;
                        this.query = '';
                        this.data = [];
                        this.$refs.form.reset();
                    }).catch(e => {
                        if (e.status !== undefined && e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    });
                }
            }
            else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        fetchResults() {
            const query = this.query;
            if (query.length < 3) {
                return;
            }
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                const config = {
                    params: {
                        key: query
                    }
                };
                StaffService.search(config).then((response) => {
                    this.data = response.data.data;
                });

            }, this.debounceMilliseconds);
        },
        getSuggestionValue(suggestion) {
            let {name, item} = suggestion;
            return item.name + ' ' + item.surname;
        },
        deleteMember(row) {
            if (this.checkPermission('boardmember_delete')) {
                this.deleteModal(() => {
                    BoardMemberService.deleteMember(row.id).then(response => {
                        this.getMembers();
                        this.$toast.success(this.$t('api.' + response.data.message));
                    }).catch(e => {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    });
                });
            }
            else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
    },
    computed: {
        suggestions() {
            return [
                {
                    data: this.data.filter(option => {
                        return option.name + ' ' + option.surname
                    })
                }
            ];
        }
    },
    watch: {
        'form.faculty_code'() {
            this.getMembers();
        }
    }

}
</script>
